.dsgvo-popup-box {
    background: #fff;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    width: 320px;
    text-align: center;
    opacity: 0;
    transform: scale(0.8);
    animation: fadeInUp 0.5s ease-out forwards;
    
}

.pu-overley {
    display: flex;
    z-index: 2;
    position: relative;
    top: -40vh;
    left: 40vw;
    transition: opacity 0.3s ease;
}



