.productCard {
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: 1.0s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
    margin: 2em auto;
    max-width: 800px;
    border-radius: 12px;
    padding-right: 0;
    padding-left: 0;
}