.overlay-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.popup-box {
    background: #fff;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    width: 320px;
    text-align: center;
    opacity: 0;
    transform: scale(0.8);
    animation: fadeInUp 0.5s ease-out forwards;
}

.form-container {
    display: flex;
    flex-direction: column;
}

.form-label {
    margin-bottom: 10px;
    font-size: 16px;
    color: #444;
    text-align: left;
}

.form-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

.btn-submit,
.btn-close-popup {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-submit {
    background-color: green;
    color: #fff;
}

.btn-close-popup {
    margin-top: 12px;
    background-color: #e74c3c;
    color: #fff;
}

.btn-submit:hover,
.btn-close-popup:hover {
    filter: brightness(110%);
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.overlay-container.show {
    display: flex;
    opacity: 1;
}

#formContainerPersonal {
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: 1.0s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
    border-radius: 12px;
}

#formContainerPersonal:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}