.registration-container{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
    margin: 2em auto;
    max-width: 800px;
}

#registrationForm {
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: 1.0s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
    padding-top: 2em;
    padding-bottom: 1em;
    padding-left: 2em;
    padding-right: 2em;
    border-radius: 12px;
    max-width: 100%;
}

#registrationForm:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.registration-labels{
    margin-bottom: 10px;
    font-size: 16px;
    color: #444;
    text-align: left;
}

.name-input {
    display: flex;
}

.input-wrapper {
    flex: 1;
}
