.checkout-section {
    margin: 10%;
    margin-top: 0 !important;
    transition: 1.0s transform cubic-bezier(.155,1.105,.295,1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
    padding: 2em;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
}

.checkout-section:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.cart-items-container,
.payment-method-container {
    margin-bottom: 20px;
}

.cart-items {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
}

.payment-method-select {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.payment-method-select:focus {
    outline: none;
    border-color: #007bff;
}

.address-select {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.address-select:focus {
    outline: none;
    border-color: #007bff;
}

