#footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 5px;
    width: 100%;
}

#footer a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
