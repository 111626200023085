.btn-close-popup-address{
    margin-top: 12px;
    background-color: grey;
    color: #fff;
}
.btn-close-popup-address,
.btn-submit-address{
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.btn-submit-address:hover,
.btn-close-popup-address:hover {
    filter: brightness(110%);
}
.btn-submit-address {
    background-color: green;
    color: #fff;
}
#adressCard {
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: 1.0s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
    margin: 2em auto;
    max-width: 800px;
    border-radius: 12px;
}

#popup-box-address {
    width: 600px;
}

.customCardCSS {
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
}

.customCardCSS:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

#addAdressCard {
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: 1.0s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
    margin: 2em auto;
    max-width: 800px;
    border-radius: 12px;
}